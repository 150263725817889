const isNoConnectedWallet = () => {
  return (
    <>
      <article>
        <section className="profile blank">
          <div className="inner-box">
            <h2 className="tit">Profile</h2>
            <div className="empty">
              <p className="desc">지갑에 연결되어 있지 않습니다.</p>
              <p className="guide">
                우측 상단의 <span className="txt-purple">“Connect Wallet”</span> 버튼을 눌러 지갑을 연결해주세요.
              </p>
            </div>
          </div>
        </section>
      </article>
    </>
  );
};

export default isNoConnectedWallet;
