import { Routes, Route } from 'react-router-dom';
import { routes } from './constants';
import HomePage from '../pages/Home';
import MintPage from '../pages/Mint';
import TermPage from '../pages/Term';
import PrivatePage from '../pages/Private';
import ProfilePage from '../pages/Profile';

const PublicRouter = () => {
  const { HOME, MINT, TERM, PRIVATE, PROFILE } = routes;
  return (
    <>
      <Routes>
        <Route path={HOME} element={<HomePage />} />
        <Route path={MINT} element={<MintPage />} />
        <Route path={TERM} element={<TermPage />} />
        <Route path={PRIVATE} element={<PrivatePage />} />
        <Route path={PROFILE} element={<ProfilePage />} />
      </Routes>
    </>
  );
};

export default PublicRouter;
