import profile_img from '../../assets/images/img_profile-pic.png';
import input_clear_img from '../../assets/images/ico_input-clear.png';
import { useRecoilState, useRecoilValue } from 'recoil';
import { accountInfoAtom } from '../../recoil/atoms';
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import useInput from '../../hooks/useInput';
import { getMyInfo, uploadImage, updateName, updateImage } from '../../api/account';
import ConfirmModal from '../Modal/Confirm';
import useModal from '../../hooks/useModal';
import MyNftLists from '../MyNftLists';
import styled from '@emotion/styled';
import { getMtNft } from '../../api/app';
import { APICore } from '../../api';

const LabelWrapper = styled.label<{ url: string }>`
  background: url(${(props) => props.url}) 50% 50% no-repeat !important;
  background-size: cover !important;
`;

export interface MyNftResult {
  name: string;
  title: string;
  description: string;
  image: string;
}
const ConnectedWallet = () => {
  const [mode, setMode] = useState<'read' | 'update'>('read');
  const [nickname, onChangeNickname, setNickname] = useInput('');
  const [accountInfo, setAccountInfo] = useRecoilState(accountInfoAtom);
  const { ModalPortal: ConfirmPortal, openModal, closeModal } = useModal();
  const [myNfts, setMyNfts] = useState<MyNftResult[]>([]);

  const [url, setUrl] = useState('');

  const inputOpenImageRef = useRef<HTMLInputElement>(null);

  const handleImageClick = () => {
    if (inputOpenImageRef && inputOpenImageRef.current) {
      inputOpenImageRef.current.click();
    }
  };

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    console.log(e.target.files, 'e.target.values');

    const formData = new FormData();
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };

    formData.append('file', e.target.files[0]);
    console.log(e.target.files[0], 'formData');
    try {
      const result = await uploadImage(formData);
      setUrl(result?.success[0].original.url);
    } catch (err) {
      alert('에러');

      // errorOpenModal();
    }
  };

  const onSave = async () => {
    try {
      if (accountInfo?.profile !== url) {
        await updateImage(url);
      }
      const result = await updateName(nickname);
      const myInfo = await getMyInfo();
      setAccountInfo({
        address: myInfo.address,
        uid: myInfo.uid,
        sid: myInfo.sid,
        nonceVerified: myInfo.nonceVerified,
        name: myInfo.name,
        profile: myInfo.profile,
      });
      console.log(result, 'result');

      setMode('read');
    } catch (err) {
      alert('에러가 발생했습니다.');
    }
  };

  const bootstrap = async () => {
    const api = new APICore();
    const myNftData = await getMtNft();
    const result = await Promise.allSettled(
      myNftData.map(async (data) => {
        const getTokenURI = await api.get(data.tokenURI).then((resp) => resp.data);
        return getTokenURI as { name: string; description: string; image: string };
      })
    );
    const buffer: MyNftResult[] = [];
    result.forEach((data, i) => {
      if (data.status === 'fulfilled') {
        buffer.push({ title: myNftData[i].name, ...data.value });
      }
    });
    setMyNfts(buffer);
    console.log(buffer, myNftData, 'myNftData');
  };

  useEffect(() => {
    bootstrap();
  }, []);

  useEffect(() => {
    setUrl(accountInfo?.profile);
  }, [accountInfo?.profile]);
  console.log(url, 'url');

  return (
    <>
      <article>
        <section className={classNames('profile', { nickname: mode === 'update' })}>
          <div className="inner-box">
            <div className="profile-info">
              {mode === 'read' ? (
                <>
                  <figure>
                    <img src={url} alt="" />
                  </figure>
                  <div className="txt">
                    <strong>{accountInfo?.name}</strong>
                    <p>{accountInfo?.address}</p>
                    <span className="btn">
                      <a
                        className="btn-gradi line"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setMode('update');
                          setNickname(accountInfo?.name);
                        }}
                      >
                        닉네임 설정
                      </a>
                      <a className="btn-gradi" style={{ cursor: 'pointer' }} onClick={openModal}>
                        NFT 업로드
                      </a>
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <div className="filebox">
                    <input className="upload-name" value="" placeholder="" readOnly />
                    <LabelWrapper url={url} htmlFor="file" />
                    <input type="file" id="file" className="upload-hidden" onChange={handleImageUpload} />
                  </div>
                  <div className="txt">
                    <span className="input">
                      <input
                        type="text"
                        placeholder="바꿀 닉네임을 입력하세요."
                        value={nickname}
                        onChange={onChangeNickname}
                      />
                      <button
                        className="btn-clear"
                        onClick={() => {
                          setNickname('');
                        }}
                      >
                        <img src={input_clear_img} alt="" />
                      </button>
                    </span>
                    <p>{accountInfo?.address}</p>
                    <span className="btn">
                      <a className="btn-gradi line btn-save" onClick={onSave} style={{ cursor: 'pointer' }}>
                        Save
                      </a>
                      <a className="btn-gradi btn-upload">NFT 업로드</a>
                    </span>
                  </div>
                </>
              )}
            </div>
            <div className="profile-cont">
              <ul className="tab">
                <li className="curr">
                  <a href="#">
                    <span className="ico-mynft">My NFT</span>
                  </a>
                </li>
                <li>
                  <a style={{ cursor: 'pointer' }} onClick={openModal}>
                    <span className="ico-onsale">On Sale</span>
                  </a>
                </li>
              </ul>
              <a onClick={openModal} className="btn-gradi line btn-nft-load" style={{ cursor: 'pointer' }}>
                NFT 불러오기
              </a>
              <div className="cont-box detail-list">
                <ul className="cont on">
                  {myNfts.map((data, i) => (
                    <MyNftLists openModal={openModal} key={i} {...data} />
                  ))}
                </ul>
                <ul className="cont">
                  <li>
                    <a href="#!">
                      <figure className="cont-img"></figure>
                    </a>
                    <div className="txt">
                      <strong className="item-tit">Artwork Title</strong>
                      <span className="collec-name">Collection Name</span>
                      <div className="btn">
                        <a href="#" className="btn-gradi">
                          Transfer
                        </a>
                        <a href="#" className="btn-gradi line">
                          Sell
                        </a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href="#!">
                      <figure className="cont-img"></figure>
                    </a>
                    <div className="txt">
                      <strong className="item-tit">Artwork Title</strong>
                      <span className="collec-name">Collection Name</span>
                      <div className="btn">
                        <a href="#" className="btn-gradi">
                          Transfer
                        </a>
                        <a href="#" className="btn-gradi line">
                          Sell
                        </a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href="#!">
                      <figure className="cont-img"></figure>
                    </a>
                    <div className="txt">
                      <strong className="item-tit">Artwork Title</strong>
                      <span className="collec-name">Collection Name</span>
                      <div className="btn">
                        <a href="#" className="btn-gradi">
                          Transfer
                        </a>
                        <a href="#" className="btn-gradi line">
                          Sell
                        </a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href="#!">
                      <figure className="cont-img"></figure>
                    </a>
                    <div className="txt">
                      <strong className="item-tit">Artwork Title</strong>
                      <span className="collec-name">Collection Name</span>
                      <div className="btn">
                        <a href="#" className="btn-gradi">
                          Transfer
                        </a>
                        <a href="#" className="btn-gradi line">
                          Sell
                        </a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href="#!">
                      <figure className="cont-img"></figure>
                    </a>
                    <div className="txt">
                      <strong className="item-tit">Artwork Title</strong>
                      <span className="collec-name">Collection Name</span>
                      <div className="btn">
                        <a href="#" className="btn-gradi">
                          Transfer
                        </a>
                        <a href="#" className="btn-gradi line">
                          Sell
                        </a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href="#!">
                      <figure className="cont-img" />
                    </a>
                    <div className="txt">
                      <strong className="item-tit">Artwork Title</strong>
                      <span className="collec-name">Collection Name</span>
                      <div className="btn">
                        <a href="#" className="btn-gradi">
                          Transfer
                        </a>
                        <a href="#" className="btn-gradi line">
                          Sell
                        </a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href="#!">
                      <figure className="cont-img" />
                    </a>
                    <div className="txt">
                      <strong className="item-tit">Artwork Title</strong>
                      <span className="collec-name">Collection Name</span>
                      <div className="btn">
                        <a href="#" className="btn-gradi">
                          Transfer
                        </a>
                        <a href="#" className="btn-gradi line">
                          Sell
                        </a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href="#!">
                      <figure className="cont-img" />
                    </a>
                    <div className="txt">
                      <strong className="item-tit">Artwork Title</strong>
                      <span className="collec-name">Collection Name</span>
                      <div className="btn">
                        <a href="#" className="btn-gradi">
                          Transfer
                        </a>
                        <a href="#" className="btn-gradi line">
                          Sell
                        </a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href="#!">
                      <figure className="cont-img"></figure>
                    </a>
                    <div className="txt">
                      <strong className="item-tit">Artwork Title</strong>
                      <span className="collec-name">Collection Name</span>
                      <div className="btn">
                        <a href="#" className="btn-gradi">
                          Transfer
                        </a>
                        <a href="#" className="btn-gradi line">
                          Sell
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </article>

      <ConfirmPortal>
        <ConfirmModal text="준비 중 입니다." onClose={closeModal} />
      </ConfirmPortal>
    </>
  );
};

export default ConnectedWallet;
