import { FC, Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  AssetsNftItem,
  getAbiData,
  getMintData,
  getNftAssetsNftLists,
  getNftProject,
  getRpcInfo,
  NftProjectReq,
  saveTxId,
} from '../../api/app';
import classNames from 'classnames';
import { SpanWrapper } from '../../pages/Mint';
import styled from '@emotion/styled';
import Caver, { AbiItem } from 'caver-js';
import { mintABI } from '../../caverConfig';
import { useRecoilState } from 'recoil';
import { accountInfoAtom, isLoginAtom } from '../../recoil/atoms';
import { isMobile } from '../../utils';
import axios from 'axios';
import { KLAYTN_MOBILE_PREPARE_URL } from '../Layout/Header';

const DivWrapper = styled.div`
  margin-bottom: 40px;

  @media (max-width: 1280px) {
    margin-bottom: 0px;
  }
`;

interface ContractData {
  to: string;
  abi: string;
  callback: () => void;
  url: string;
  currentProject: NftProjectReq | null;
  idx: number;
  tag: string;
  coinAddress: string;
  decimals: number;
}

const SpanImgWrapper = styled.span`
  width: 24px !important;
  padding-left: 0px !important;
  display: flex !important;
  gap: 10px;
  position: relative;
  bottom: 20px;
  @media (max-width: 1280px) {
    padding-left: 3px !important;
    display: flex !important;
    gap: 10px;
    align-items: center;
    width: 23px;
    bottom: 0px;
  }
`;

const ImgWrapper = styled.img`
  border-radius: 12px;
`;

interface Props extends AssetsNftItem {
  currentProject: NftProjectReq | null;
  successOpenModal: () => void;
  failOpenModal: () => void;
  setFailMintMessage: Dispatch<SetStateAction<string>>;
  setCurrentNftProject: Dispatch<SetStateAction<NftProjectReq | null>>;
  setNftAssetsLists: Dispatch<SetStateAction<AssetsNftItem[]>>;
}

const NftAssets: FC<Props> = ({
  idx,
  thumbnail,
  price,
  maximum,
  minted,
  soldout,
  title,
  ticker,
  successOpenModal,
  setFailMintMessage,
  failOpenModal,
  currentProject,
  setCurrentNftProject,
  setNftAssetsLists,
  decimal,
  contract,
  abi,
}) => {
  const [accountInfo, setAccountInfo] = useRecoilState(accountInfoAtom);
  const [isLogin, setIsLogin] = useRecoilState(isLoginAtom);
  const [coinApproveABI, setCoinApproveABI] = useState('');
  const [nftMintABI, setNftMintABI] = useState('');
  const [nftABI, setNFTABI] = useState<AbiItem[]>([]);
  const onSignOut = () => {
    localStorage.removeItem('isLogin');
    window.location.reload();
  };

  const onFailMintHandler = (errMessage: string) => {
    failOpenModal();
    setFailMintMessage(errMessage);
  };

  const executeContract = async ({
    to,
    abi,
    callback,
    url,
    currentProject,
    idx,
    tag,
    coinAddress,
    decimals,
  }: ContractData) => {
    const params = `[\"${to}\",\"${Number(decimals)}\"]`;
    // `{"inputs": [{"internalType": "address","name":"spender","type":"address"}, {"internalType":"uint256","name":"amount","type":"uint256"}],"name":"approve","outputs":[{"internalType":"bool","name":"","type":"bool"}], "stateMutability":"nonpayable", "type":"function"}`
    try {
      const approveHandler = await axios
        .post(KLAYTN_MOBILE_PREPARE_URL, {
          bapp: { name: 'metacolabo' },
          type: 'execute_contract',
          transaction: {
            to: coinAddress,
            from: accountInfo.address,
            abi: coinApproveABI,
            value: '0',
            params,
          },
        })
        .then(async (resp) => {
          const { request_key } = resp.data;

          window.location.href = `kaikas://wallet/api?request_key=${request_key}`;
          let timerId = setInterval(async () => {
            await axios
              .get(`https://api.kaikas.io/api/v1/k/result/${request_key}`)
              .then(async (res) => {
                if (res.data.result) {
                  alert('잠시만 기다려주세요.');
                  await axios
                    .post(KLAYTN_MOBILE_PREPARE_URL, {
                      bapp: { name: 'metacolabo' },
                      type: 'execute_contract',
                      transaction: {
                        to,
                        abi: nftMintABI, // `{ "inputs": [], "name": "mint", "outputs": [], "stateMutability": "nonpayable", "type": "function"}`
                        value: '0',
                        params: null,
                      },
                    })
                    .then(async (response) => {
                      const { request_key } = response.data;
                      window.location.href = `kaikas://wallet/api?request_key=${request_key}`;
                      let timerId = setInterval(async () => {
                        await axios
                          .get(`https://api.kaikas.io/api/v1/k/result/${request_key}`)
                          .then(async (res) => {
                            if (res.data.result) {
                              await saveTxId(currentProject!.idx, idx, res.data.result.tx_hash, tag);
                              const projectInfo = await getNftProject(currentProject!.idx);
                              const assetsInfo = await getNftAssetsNftLists(currentProject!.idx);
                              successOpenModal();
                              setCurrentNftProject(projectInfo);
                              setNftAssetsLists(assetsInfo);
                              clearInterval(timerId);
                            }
                          })
                          .catch((err) => {
                            alert(err);
                          });
                      }, 1000);
                    })
                    .catch((error) => {
                      console.log(error);
                      alert(error);
                    });
                  clearInterval(timerId);
                }
              })
              .catch((err) => {
                alert(err);
              });
          }, 1000);
        });
    } catch (err) {
      alert(err);
    }
  };

  const mintAsset = async (contractAddress: string) => {
    // const data = await window.klaytn.selectedAddress;

    if (!isLogin || !accountInfo?.address) {
      alert('kaikas 지갑을 연결해주세요.');
      return;
    }
    if (!window.klaytn && !isMobile()) {
      alert('kaikas 지갑을 설치해주세요.');
      return;
    }

    if (accountInfo.address) {
      try {
        const { url, tag, gasFee } = await getMintData(currentProject!.idx, idx);
        const caver = new Caver(window.klaytn);
        const decimals = caver.utils.toBN(price * 10 ** decimal).toNumber();

        const callback = () => {
          console.log('test');
        };

        if (isMobile()) {
          await executeContract({
            to: contractAddress,
            abi,
            callback,
            url,
            currentProject,
            idx,
            tag,
            coinAddress: contract,
            decimals,
          });
          return;
        }

        const mintContract = caver.contract.create(nftABI, contractAddress);
        const coinContract = caver.contract.create(JSON.parse(abi), contract);
        await caver.klay.sendTransaction({
          type: 'SMART_CONTRACT_EXECUTION',
          from: accountInfo.address,
          to: contract,
          gas: String(gasFee),
          data: coinContract.methods.approve(contractAddress, decimals).encodeABI(),
        });

        const response = await caver.klay.sendTransaction({
          type: 'SMART_CONTRACT_EXECUTION',
          from: accountInfo.address,
          to: contractAddress,
          gas: String(gasFee),
          data: mintContract.methods.mint().encodeABI(),
        });
        if (response.status) {
          await saveTxId(currentProject!.idx, idx, response.transactionHash, tag);
          const projectInfo = await getNftProject(currentProject!.idx);
          const assetsInfo = await getNftAssetsNftLists(currentProject!.idx);
          successOpenModal();
          setCurrentNftProject(projectInfo);
          setNftAssetsLists(assetsInfo);
        }

        if (!response.status) {
          await saveTxId(currentProject!.idx, idx, response.transactionHash, tag);
        }
      } catch (err: any) {
        console.log(err.message, 'err');

        if (err.message.includes('WalletMiddleware - Invalid "from" address')) {
          alert('kaikas 지갑이 연결이 안되어 로그아웃을 합니다. 다시 로그인 해주세요.');
          onSignOut();

          return;
        }

        if (err.message.includes('Returned error: Error: Kaikas Tx Signature: User denied transaction signature.')) {
          return;
        }
        if (err.message.includes('Returned error: Error: WalletMiddleware - Invalid "from" address.')) {
          alert('kaikas 지갑이 연결이 안되어 로그아웃을 합니다. 다시 로그인 해주세요.');
          onSignOut();
          return;
        }
        onFailMintHandler('민팅 요청에 실패 했습니다. 다시 시도해주세요.');
        // alert(err);
      }
    }
  };

  const bootstrap = async () => {
    const coinApprove = (await getAbiData('coin', 'approve', currentProject!.idx, idx)) as any;
    const nftMint = (await getAbiData('nft', 'mint', currentProject!.idx, idx)) as any;
    const mainABI = await getAbiData('nft', '', currentProject!.idx, idx);

    setNftMintABI(JSON.stringify(nftMint.nft.abi));
    setCoinApproveABI(JSON.stringify(coinApprove.coin.abi));
    setNFTABI(mainABI.nft.abi);
  };

  useEffect(() => {
    bootstrap();
  }, []);

  return (
    <li className={classNames({ 'sold-out': soldout })}>
      <figure className="cont-img">
        <ImgWrapper src={thumbnail} alt="" />
      </figure>
      <div className="info">
        <strong
          className="small-tit"
          onClick={() => {
            successOpenModal();
          }}
        >
          {title}
        </strong>
        <div className="graph-wrap">
          <DivWrapper className="flex">
            <div>
              <span className="quantity-tit">남은 수량</span>
              <em>{maximum - minted}</em>
            </div>
            <div className="text-r">
              <span className="quantity-tit">총 수량</span>
              <em>{maximum}</em>
            </div>
          </DivWrapper>
          <div className="graph graph01">
            <SpanWrapper width={`${String(parseInt(String((minted / maximum) * 100)))}%`} className="bar">
              <i className="num">{minted}</i>
            </SpanWrapper>
          </div>
        </div>

        <SpanImgWrapper className="klay">
          <img src={ticker} alt="" />
          {price}
        </SpanImgWrapper>
        <a
          className="btn-gradi"
          style={{ cursor: 'pointer' }}
          onClick={async () => {
            if (currentProject?.blockchain) {
              await mintAsset(currentProject?.blockchain.contract);
            }
          }}
        >
          구매하기
        </a>
      </div>
    </li>
  );
};

export default NftAssets;
