import ico_globe from '../../../assets/images/ico_globe.png';
import btn_menu_image from '../../../assets/images/btn_menu.png';
import { useRecoilState } from 'recoil';
import { accountInfoAtom, currentLangAtom, isLoginAtom, pathnameInfoAtom } from '../../../recoil/atoms';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import i18n from 'i18next';
import { localeLangsUtil } from '../../../libs/locale';
import { isMobile } from '../../../utils';
import { getMyInfo, signIn, verifyUser } from '../../../api/account';
import Caver from 'caver-js';
import { APICore, getUserFromSession, setAuthorization } from '../../../api';
import styled from '@emotion/styled';
import logo_w from '../../../assets/images/logo_w.png';
import close_btn from '../../../assets/images/btn_menu-close.png';
import { hoverStyle } from '../../../styles/constants';
import axios from 'axios';

const ImgWrapper = styled.img`
  border-radius: 50%;
`;
const api = new APICore();

export const pointerStyle = { cursor: 'pointer' };
export const KLAYTN_MOBILE_PREPARE_URL = 'https://api.kaikas.io/api/v1/k/prepare';
export const headerLists = [
  { text: 'Marketplace', link: '/' },
  { text: 'Mint', link: '/mint' },
  { text: 'Profile', link: '/profile' },
  // { text: 'upload', link: '/upload' },
  // { text: 'My Nft', link: '/my-nft' },
];

const MobileNavContainer = styled.div<{ click: boolean }>`
  @media (max-width: 1280px) {
    right: ${(props) => (props.click ? '0px' : '-100%')};
    transition: all 0.25s;
  }
`;

const Header = () => {
  const [pathnameInfo, setPathnameInfo] = useRecoilState(pathnameInfoAtom);
  const [isHover, setIsHover] = useState(false);
  const [accountInfo, setAccountInfo] = useRecoilState(accountInfoAtom);
  const [currentLanguage, setCurrentLanguage] = useRecoilState(currentLangAtom);
  const [isLogin, setIsLogin] = useRecoilState(isLoginAtom);
  const [isProfileHover, setIsProfileHover] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const changePathNameInfo = () => {
    const getPathInfo = headerLists.filter((path) => path.link === location.pathname);
    setPathnameInfo(getPathInfo[0]);
  };

  const onMouseLeaveHandler = () => {
    setIsHover(false);
  };

  const onMouseHoverHandler = () => {
    setIsHover(true);
  };

  const handleConnectWallet = async () => {
    const api = new APICore();

    // if (isMobile()) {
    //   window.open('https://app.kaikas.io/u/https://www.metacolabo.com/');
    // }

    // if (isMobile()) {
    //   alert('PC에서 연결해주세요.');
    //   return;
    // }

    if (!window.klaytn) {
      alert('클레이튼 지갑을 설치해주세요.');
      return;
    }
    const caver = new Caver(window.klaytn);
    try {
      const accounts = await window.klaytn.enable();

      const publicAddress = accounts[0].toLowerCase();
      const signInData = await signIn(publicAddress);
      const signMessage = signInData.sigmessage.split(':')[0] + ':';
      // const signedMessage = await caver.rpc.klay.sign(publicAddress, 'my message');

      const signature = await caver.klay.sign(`${signMessage} ${signInData.nonce}`, publicAddress);

      const body = { address: publicAddress, signature };
      await verifyUser(body, signInData.token);
      setAuthorization(signInData.token);
      const myInfoData = await getMyInfo();
      if (JSON.parse(myInfoData.nonceVerified)) {
        api.setLoggedInUser(signInData.token);
        setAccountInfo({
          address: myInfoData.address,
          uid: myInfoData.uid,
          sid: myInfoData.sid,
          nonceVerified: myInfoData.nonceVerified,
          name: myInfoData.name,
          profile: myInfoData.profile,
        });
        setIsLogin(true);
        localStorage.setItem('isLogin', 'true');
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onSignOut = () => {
    localStorage.removeItem('isLogin');
    api.setLoggedInUser(null);

    window.location.reload();
  };

  const onCloseNav = () => {
    const content = document.querySelector('#contents')!;
    const html = document.querySelector('html')!;
    content.classList.remove('dim');
    html.classList.remove('nav-open');
    setIsOpen(!isOpen);
  };

  const mobileSign = async (address: string) => {
    try {
      const publicAddress = address.toLowerCase();
      const signInData = await signIn(publicAddress);
      const signMessage = signInData.sigmessage.split(':')[0] + ':';
      const body = {
        type: 'sign',
        bapp: { name: 'metacolabo' },
        sign: { message: `${signMessage} ${signInData.nonce}` },
      };
      const { request_key } = (await axios.post(`${KLAYTN_MOBILE_PREPARE_URL}`, body).then((resp) => resp.data)) as any;

      window.location.href = `kaikas://wallet/api?request_key=${request_key}`;

      let timerId = setInterval(async () => {
        await axios
          .get(`https://api.kaikas.io/api/v1/k/result/${request_key}`)
          .then(async (res) => {
            if (res.data.result) {
              const body = { address: publicAddress, signature: res.data.result.signed_data };
              await verifyUser(body, signInData.token);
              setAuthorization(signInData.token);
              const myInfoData = await getMyInfo();
              if (JSON.parse(myInfoData.nonceVerified)) {
                api.setLoggedInUser(signInData.token);
                setAccountInfo({
                  address: myInfoData.address,
                  uid: myInfoData.uid,
                  sid: myInfoData.sid,
                  nonceVerified: myInfoData.nonceVerified,
                  name: myInfoData.name,
                  profile: myInfoData.profile,
                });
                setIsLogin(true);
                localStorage.setItem('isLogin', 'true');
              }
              clearInterval(timerId);
            }
          })
          .catch((err) => {
            alert(err);
            alert('error');
          });
      }, 1000);
    } catch (err) {}
  };

  const connectMobile = async () => {
    try {
      const body = {
        bapp: {
          name: 'test',
        },
        type: 'auth',
        callback: { success: 'https://www.google.com/search?q=success', fail: 'https://www.google.com/search?q=fail' },
      };
      const result = await axios
        .post('https://api.kaikas.io/api/v1/k/prepare', body)
        .then((resp) => {
          return resp.data as any;
        })
        .catch((err) => console.log(err));
      const { request_key } = result;
      window.location.href = `kaikas://wallet/api?request_key=${request_key}`;
      let timerId = setInterval(async () => {
        await axios
          .get(`https://api.kaikas.io/api/v1/k/result/${request_key}`)
          .then(async (res) => {
            if (res.data.result) {
              console.log(res.data.result);
              alert('잠시만 기다려주세요.');
              await mobileSign(res.data.result.klaytn_address);
              setAccountInfo(res.data.result.klaytn_address);

              clearInterval(timerId);
            }
          })
          .catch((err) => {
            alert(err);
            alert('error');
          });
      }, 1000);
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };

  const openMenu = () => {
    const content = document.querySelector('#contents')!;
    const html = document.querySelector('html')!;

    content.setAttribute('class', 'dim');
    html.setAttribute('class', 'nav-open');
    setIsOpen(!isOpen);
  };

  const bootstrap = async () => {
    const getToken = getUserFromSession();

    window.klaytn.on('accountsChanged', function (accounts: string[]) {
      setAccountInfo({ ...accountInfo, address: accounts[0] });
    });

    window.klaytn.on('networkChanged', function (data: any) {
      // `networkChanged` event is only useful when auto-refresh on network is disabled
      // Otherwise, Kaikas will auto-reload pages upon network change
    });
    if (getToken) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  };

  useEffect(() => {
    changePathNameInfo();
  }, [location.pathname]);

  useEffect(() => {
    bootstrap();
  }, []);
  return (
    <>
      <header>
        <section
          className="header-inner"
          onMouseLeave={() => {
            setIsProfileHover(false);
          }}
        >
          <h1
            onClick={() => {
              navigate('/');
            }}
          >
            <a style={{ cursor: 'pointer' }}>Meta colabo logo</a>
          </h1>
          <div>
            <nav>
              <ul className="gnb">
                {headerLists.map((item, i) => (
                  <li key={i} className={classNames({ curr: pathnameInfo?.text === item.text })}>
                    <Link to={item.link}>{item.text}</Link>
                  </li>
                ))}
              </ul>
            </nav>
            {isLogin && accountInfo ? (
              <div
                className="connected"
                onMouseOver={() => {
                  setIsProfileHover(true);
                }}
              >
                <div className="nick-wrap">
                  <div className="nick-thumb">
                    <ImgWrapper src={accountInfo?.profile} />
                  </div>
                  <div className="nick-txt">
                    <button className={classNames({ down: true, on: isProfileHover })}>
                      {accountInfo?.address?.slice(0, 6)}...
                    </button>
                    <span>Wallet Connected</span>
                  </div>
                </div>
                <div className="connect-select select-type02" style={{ display: isProfileHover ? 'block' : 'none' }}>
                  <ul>
                    <li className="ico-connect01">
                      <Link to="/profile" style={pointerStyle}>
                        My Profile
                      </Link>
                    </li>
                    {/*<li className="ico-connect02">*/}
                    {/*  <a style={pointerStyle}>Transactions</a>*/}
                    {/*</li>*/}
                    <li className="ico-connect03" onClick={onSignOut}>
                      <a style={pointerStyle}>Sign out</a>
                    </li>
                  </ul>
                </div>
              </div>
            ) : (
              <a
                className="btn-gradi btn-wallet"
                style={hoverStyle}
                data-popup-target="#connectWallet"
                onClick={handleConnectWallet}
              >
                Connect Wallet
              </a>
            )}

            <div className="select-box">
              {/*<button className={classNames('down', { on: isHover })}>*/}
              {/*  <img src={ico_globe} alt="" />*/}
              {/*</button>*/}
              <div style={{ display: isHover ? 'block' : 'none' }}>
                <ul
                  onClick={(e) => {
                    const eText = (e.target as HTMLLIElement).textContent!;
                    setCurrentLanguage(eText);
                    const convertLang = localeLangsUtil(eText);
                    i18n.changeLanguage(convertLang);
                  }}
                >
                  <li>
                    <a>KOR</a>
                  </li>
                  <li>
                    <a>ENG</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </header>
      <button className="btn-menu" onClick={openMenu}>
        button
      </button>
      <MobileNavContainer click={isOpen} className="mobile-nav">
        <a
          className="close"
          onClick={() => {
            onCloseNav();
            setIsProfileHover(false);
          }}
        >
          <img src={close_btn} alt="" />
        </a>

        <div className="top">
          <Link to="/" className="logo">
            <img src={logo_w} alt="" />
          </Link>
        </div>

        <div
          className="gnb-list"
          onMouseLeave={() => {
            setIsProfileHover(false);
          }}
        >
          {isLogin && accountInfo?.address && (
            <div
              className="connected"
              onMouseOver={() => {
                setIsProfileHover(true);
              }}
            >
              <div className="nick-wrap">
                <div className="nick-thumb">
                  <ImgWrapper src={accountInfo?.profile} />{' '}
                </div>
                <div className="nick-txt">
                  <button className={classNames({ down: true, on: isProfileHover })}>
                    {accountInfo.address.slice(0, 6)}...
                  </button>
                </div>
              </div>
              <div className="connect-select select-type02" style={{ display: isProfileHover ? 'block' : 'none' }}>
                <ul>
                  <li style={{ cursor: 'pointer' }} onClick={onCloseNav}>
                    <Link to="/profile">My Profile</Link>
                  </li>
                  <li className="ico-logout" style={{ cursor: 'pointer' }}>
                    <a onClick={onSignOut}>Log out</a>
                  </li>
                </ul>
              </div>
            </div>
          )}
          <ul className="gnb">
            {headerLists.map((item, i) => (
              <li
                className={classNames({ curr: pathnameInfo?.text === item.text })}
                key={i}
                onClick={() => {
                  onCloseNav();
                }}
              >
                <Link to={item.link}>{item.text}</Link>
              </li>
            ))}
          </ul>
          {!isLogin && !window.klaytn && isMobile() ? (
            <a
              onClick={async () => {
                // window.open('https://app.kaikas.io/u/https://www.metacolabo.com/');
                await connectMobile();
              }}
              className="btn-gradi btn-wallet"
              data-popup-target="#connectWallet"
            >
              Connect Wallet
            </a>
          ) : !isLogin ? (
            <a onClick={handleConnectWallet} className="btn-gradi btn-wallet" data-popup-target="#connectWallet">
              Connect Wallet
            </a>
          ) : (
            <a onClick={onSignOut} className="btn-gradi line" style={{ cursor: 'pointer' }}>
              Disconnect & Log out
            </a>
          )}{' '}
        </div>
      </MobileNavContainer>
    </>
  );
};

export default Header;
