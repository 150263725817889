const PrivatePage = () => {
  return (
    <article>
      <section className="meta_terms">
        <h2 className="tit">메타콜라보 개인정보 이용 동의</h2>
        <div className="terms-wrap">
          <div className="terms-box">
            <p>
              개인정보 수집 및 이용 동의(주)제이엔피글로벌파이낸스(이하 "회사")는 고객님의 개인정보를 중요시하며,
              "정보통신망 이용촉진 및 정보보호"에 관한 법률을 준수하고 있습니다.
            </p>
          </div>

          <div className="terms-box">
            <h3 className="terms-tit">1. 개인정보처리방침의 의의</h3>
            <p>
              회사는 개인정보처리방침을 통하여 고객님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며,
              개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.
            </p>
          </div>

          <div className="terms-box">
            <h3 className="terms-tit">2.수집하는 개인정보 항목 및 수집방법</h3>
            <ul style={{ lineHeight: '24px' }}>
              <li>가. 수집하는 개인정보의 항목</li>

              <li>1) 회사는 회원가입, 상담, 서비스 신청 등을 위해 아래와 같은 개인정보를 수집하고 있습니다.</li>
              <li>- 서비스 신청시 : 지갑 ID, 지갑 주소, 암호화 된 지갑 키, IP</li>

              <li>
                2) 서비스 이용과정이나 사업 처리 과정에서 서비스 이용기록, 접속로그, 쿠키, 접속 IP, 결제 기록,
                불량이용기록이 생성되어 수집될 수 있습니다.
              </li>
              <li>나. 수집방법</li>
              <li>
                1) 회원가입 및 메타콜라보 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의를 하고 직접 정보를
                입력하는 경우, 해당 개인정보를 수집합니다.
              </li>
              <li>
                2) 고객센터를 통한 상담 과정에서 웹페이지, 메일, 팩스, 전화 등을 통해 이용자의 개인정보가 수집될 수
                있습니다.
              </li>

              <li>
                3) 기기정보와 같은 생성정보는 PC웹, 모바일 웹/앱 이용 과정에서 자동으로 생성되어 수집될 수 있습니다.
              </li>
            </ul>
          </div>

          <div className="terms-box">
            <h3 className="terms-tit">3. 개인정보의 수집 및 이용목적</h3>
            <p>회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.</p>

            <ul style={{ lineHeight: '24px' }}>
              <li>
                1) 메타콜라보 서비스 제공에 대한 계약 이행 및 서비스 제공에 따른 지갑연동, 요금정산, 콘텐츠 제공, 구매
                및 요금 결제, 금융거래 본인 인증 및 금융 서비스
              </li>
              <li>
                2) 회원 관리 회원제 서비스 이용에 따른 본인확인, 개인 식별, 불량회원의 부정 이용 방지와 비인가 사용
                방지, 가입 의사 확인, 연령확인, 만 14세 미만 아동 개인정보 수집 시 법정 대리인 동의여부 확인, 불만처리
                등 민원처리, 고지사항 전달
              </li>
            </ul>
          </div>

          <div className="terms-box">
            <h3 className="terms-tit">3. 프로모션 정보 수신 동의 (선택)</h3>
            <p>회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.</p>

            <ul style={{ lineHeight: '24px' }}>
              <p>
                메타콜라보에서 제공하는 이벤트 등 광고성 정보 전달, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계,
                개인화된 추천 제공 프로모션 정보 수신 동의를 거부하실 경우, 이벤트 프로모션 정보를 받을 수 없습니다.
              </p>
            </ul>
          </div>
        </div>
      </section>
    </article>
  );
};

export default PrivatePage;
