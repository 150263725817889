const TermPage = () => {
  return (
    <article>
      <section className="meta_terms">
        <h2 className="tit">메타콜라보 ‘META COLABO’ 이용약관</h2>
        <div className="terms-wrap">
          <div className="terms-box">
            <h3 className="terms-tit">제 1조 (목적)</h3>
            <p>
              본 이용약관은 메타콜라보 이용약관에 동의한 메타콜라보 회원(이하 "회원"이라고 합니다)이
              (주)제이엔피글로벌파이낸스(이하 "회사"라고 합니다)가 제공하는 메타콜라보의 NFT 서비스(본 약관 제2조에서
              정의합니다)를 거래함에 있어 회사와 회원의 권리와 의무, 기타 필요한 사항을 규정함을 목적으로 합니다.
            </p>
          </div>
          <div className="terms-box">
            <h3 className="terms-tit">제 2조 (정의)</h3>
            <ul style={{ lineHeight: '24px' }}>
              <li>
                1. "NFT"란, 대체 불가능한 토큰(Non-Fungible Token)으로서, ①이미지, 동영상, 음악, 서적 등의 "디지털
                저작물"에 대한 메타데이터 정보를 포함한 상태로 발행되어, ②해당 NFT 소유자에게 상기 "디지털 저작물"에
                관한 특정 권리(이하 "NFT 소유자의 권리"라고 합니다)가 부여되는 블록체인 기반의 디지털 자산을 의미합니다.
              </li>
              <li>
                2. "디지털 저작물"이란, NFT에 연계된 디지털 이미지, 동영상, 음악, 서적 등의 디지털 저작물을 의미합니다.
              </li>
              <li>
                3. NFT 소유자의 권리"란, NFT가 메타콜라보에서 최초로 판매되는 시점에 고지된 권리를 의미합니다. "NFT
                소유자의 권리"는 NFT에 연계된 "디지털 저작물"을 일정 한도 내에서 이용할 수 있는 권한을 의미할 뿐 "디지털
                저작물" 자체에 대한 소유권, 저작권을 포함한 지식재산권, 기타 권리를 취득하는 것을 의미하는 것은
                아닙니다. 각 NFT 별로 "NFT 소유자의 권리"의 내용은 다를 수 있습니다.
              </li>
              <li>
                4. "디지털 저작물 온라인 전시장"이란, NFT와 연계된 "디지털 저작물"을 조회하고 감상할 수 있는 웹사이트 및
                앱 등의 온라인 화면 및 그 구현에 필요한 서버, 데이터베이스 등을 의미합니다.
              </li>
              <li>
                5. "NFT 마켓플레이스"란, NFT를 거래할 수 있는 온라인 상의 장소 및 그 구현에 필요한 서버, 데이터베이스
                등을 의미합니다. 별도의 기재가 없는 한 본 약관에서 "NFT 마켓플레이스"라 함은 회사가 제공하는 서비스인
                메타콜라보를 의미합니다.
              </li>
              <li>
                6. "NFT 발행인"이란, 다음 각 목의 행위를 하는 데 필요한 일체의 권한(이하 "NFT IP"라고 합니다)을 적법하게
                확보한 후, NFT를 발행하여 최초로 배포하는 자를 의미합니다. 일반적으로는 NFT와 연계된 디지털 저작물을
                제작한 자가 "NFT 발행인"이 됩니다.
              </li>
              <li style={{ paddingLeft: '10px' }}>
                1) "디지털 저작물" 제작 [① "디지털 저작물" 자체에 대한 지식재산권을 취득하는 경우, ② 원저작물
                저작권자와의 디지털 저작물 제작 위탁계약 등을 통하여 "디지털 저작물" 제작에 필요한 지식재산권 또는 이에
                관한 라이선스를 취득하는 경우를 포함합니다.]
              </li>
              <li style={{ paddingLeft: '10px' }}>
                2) 직접 또는 타인이 제공하는 솔루션 또는 거래 중개 서비스를 이용하여 "디지털 저작물"과 연계된 NFT 발행
                및 유통
              </li>
              <li style={{ paddingLeft: '10px' }}>3) 발행 및 유통된 NFT를 소유한 자에게 "NFT 소유자의 권리" 보장</li>
              <li>
                7. "외부 출금"이란, 회사가 운영하는 가상자산 지갑으로부터 회사가 아닌 다른 가상자산사업자가 운영하는
                가상자산 지갑주소 또는 외부의 개인 지갑주소로 가상자산을 출금하는 행위를 의미합니다.
              </li>
            </ul>
          </div>
          <div className="terms-box">
            <h3 className="terms-tit">제 3조 (NFT 소유자의 권리)</h3>
            <p>
              1. NFT를 보유한 자에게는 "NFT 소유자의 권리"의 내용에 따라 NFT와 연계된 디지털 저작물을 이용할 수 있는
              권리만이 부여됩니다. NFT는 저작권, 상표권을 포함한 지식재산권이나 초상권, 상품화 권리 등의 특정 권리 그
              자체를 의미하지 않으며, NFT를 보유하는 것이 NFT와 연계된 디지털 저작물에 대한 보유 내지 디지털 저작물에
              관한 저작권 등의 권리를 취득하는 것을 의미하지 않습니다. 2. "NFT 소유자의 권리"는 NFT를 적법하게 보유하고
              있는 동안에만 행사할 수 있으며, "NFT 소유자의 권리"는 권리를 행사하는 시점에 해당 NFT를 보유한 자에게
              인정됩니다. 타인에게 NFT를 이전한 경우, NFT를 양도한 회원은 "NFT 소유자의 권리"를 더 이상 행사할 수
              없습니다. 회사가 공식적으로 지원하는 방식이 아닌 방식 또는 관련 법령을 위반하는 방식으로 NFT를 이전받은
              자에게는 "NFT 소유자의 권리"가 보장되지 않을 수 있습니다.
            </p>
          </div>

          <div className="terms-box">
            <h3 className="terms-tit">제 4조 (NFT 관련 유의사항)</h3>
            <ul style={{ lineHeight: '24px' }}>
              <li>
                1. 메타콜라보에서 거래되는 NFT는 "NFT 발행인"은 회사가 제공하는 솔루션을 활용하여 NFT를 발행하며, 상기
                방식으로 발행된 NFT는 별도의 개발 지원이 없는 한 타 지갑으로 전송되지 않습니다.
              </li>
              <li>
                2. 회사는 NFT에 연계된 "디지털 저작물"의 진정성, 권리의 완전성 등에 대하여 보증하지 않습니다. 단, 회사의
                고의 또는 과실로 인하여 손해가 발생한 경우에는 법령에서 정한 범위 내에서 회사가 책임을 부담합니다.
              </li>
              <li>
                3. 다음 각 호의 사유에 해당하는 경우 회사는 NFT 거래 중개 서비스를 일부 또는 전부 종료할 수 있으며, 이
                경우 거래 중개 서비스가 종료된 NFT를 보유한 자는 "NFT 소유자의 권리"를 더 이상 행사할 수 없습니다. 단,
                회사는, NFT 거래 중개 서비스 제공을 종료한 시점의 NFT 소유자가 NFT에 연계된 디지털 저작물 원본 파일을
                종료일로부터 적어도 삼십(30)일간 다운로드할 수 있도록 지원합니다. 위 NFT 소유자는 타인에게 공개되지 않는
                개인 저장 공간(제3자가 열람할 수 없도록 설정한 개인 SNS, 웹클라우드, PC 등)에 저장하는 등 개인 소장
                목적으로 디지털 저작물을 계속 이용할 수 있습니다.
              </li>
              <li style={{ paddingLeft: '10px' }}>
                1) 국가 기관의 지시, 권고 또는 관련 법령에 의할 때 기존 NFT 관련 서비스의 전부 또는 일부를 종료할 필요가
                있는 경우
              </li>
              <li style={{ paddingLeft: '10px' }}>2) 천재지변 등의 사유로 서비스 중단 사유에 해당하는 경우</li>
              <li style={{ paddingLeft: '10px' }}>3) 디지털 자산 거래지원 종료 사유에 해당하는 경우</li>
              <li>
                4. NFT 소유권 이전에 따른 제세공과금이 발생할 경우 이는 NFT 소유권을 이전한 자가 부담하여야 합니다.
              </li>
              <li>
                5. 회사는 메타콜라보 내 NFT 거래 중개에 관한 홍보 목적으로 NFT에 연계된 디지털 저작물을 이용할 수
                있습니다.
              </li>
              <li>
                6. "디지털 저작물 온라인 전시장" 개설 여부는 각 NFT별로 달라질 수 있고, 회사는 각 NFT의 "디지털 저작물
                온라인 전시장" 개설을 보장하지 않습니다.
              </li>
              <li>
                7. "NFT 마켓플레이스"에서 제공되는 서비스의 범위(거래 지원 여부, 거래 지원 기간, 입금 및 외부 출금 지원
                여부를 포함하며, 이에 한정하지 않습니다)는 각 NFT별로 상이할 수 있으며, 회사는 위 서비스 범위에 관한
                독자적인 판단 권한을 갖습니다.
              </li>
              <li>
                8. "NFT 마켓플레이스"에서 배포 또는 거래되는 모든 NFT에 대하여, 각 NFT의 발행인, 거래 일시, 거래
                당사자인 회원의 지갑명, 거래 금액, 현재 판매 여부 및 판매 금액이 서비스 화면 내 전부 또는 일부 공개될 수
                있습니다.
              </li>
            </ul>
          </div>

          <div className="terms-box">
            <h3 className="terms-tit">제 5조 (금지 행위 및 해지)</h3>
            <ul style={{ lineHeight: '24px' }}>
              <li>
                1. 회원은 메타콜라보에서 유통된 NFT 및 이에 연계된 디지털 저작물과 관련하여 다음 각 호의 행위를 할 수
                없습니다.
              </li>
              <li style={{ paddingLeft: '10px' }}>1) 디지털 저작물을 수정, 왜곡하여 게시하는 행위</li>
              <li style={{ paddingLeft: '10px' }}>
                2) 본인 또는 제3자의 제품 또는 서비스를 광고하는 등 디지털 저작물을 영리 목적으로 이용하는 행위
              </li>
              <li style={{ paddingLeft: '10px' }}>3) 디지털 저작물과 연계된 별도의 NFT를 발행하는 행위</li>
              <li style={{ paddingLeft: '10px' }}>
                4) "NFT 소유자의 권리"에 따라 이용 가능한 디지털 저작물을 악용하여, 별도의 지식재산권을 등록, 취득하는
                행위
              </li>
              <li style={{ paddingLeft: '10px' }}>
                5) 특정 회원과 통정하여 반복 매매하는 방식 등을 통하여 NFT 가격을 비정상적으로 변동시키는 행위
              </li>
              <li>
                2. 회원이 전항을 위반한 경우 회사는 서비스 이용을 제한함과 동시에 시정요구를 할 수 있습니다.
                시정요구에도 불구하고 상당한 기간 내에 시정되지 않거나 2회 이상 반복적으로 같은 위반행위를 하는 경우에는
                메타콜라보 서비스 이용계약을 해지할 수 있습니다.
              </li>
              <li>
                3. 회원은 NFT를 보유한 상태에서는 서비스를 탈퇴할 수 없습니다. 회원은 보유한 NFT를 처분하거나 출금한 후
                서비스를 탈퇴할 수 있습니다. 단, 회원은 회사에게 NFT의 보관을 요청한 후 서비스를 탈퇴할 수 있고, 이 경우
                회사는 최대 5년 간 회원이 보관을 의뢰한 NFT를 안전하게 보관합니다.
              </li>
            </ul>
          </div>

          <div className="terms-box">
            <h3 className="terms-tit">제 6조 (NFT 거래 방법과 회사의 역할)</h3>
            <ul style={{ lineHeight: '24px' }}>
              <li>1. NFT 거래는 회사가 정하여 안내하는 방식으로 진행됩니다.</li>
              <li>2. 가격 조작을 목적으로 한 허위 입찰을 방지하기 위하여 입찰 취소가 제한될 수 있습니다.</li>
              <li>
                3. 회사는 NFT거래 중개자이며, NFT거래 당사자가 아닙니다. NFT거래에 관한 책임은 판매자가 부담하고, 회사는
                판매자를 대리하거나 그 어떠한 보증을 하지 않습니다. 단, 회사의 고의 또는 과실로 인하여 회원에게 손해가
                발생한 경우 회사는 그 손해를 배상합니다.
              </li>
            </ul>
          </div>

          <div className="terms-box">
            <h3 className="terms-tit">제 7조(디지털 저작물 관련 이의제기)</h3>
            <ul style={{ lineHeight: '24px' }}>
              <li>
                1. 회사가 제공하는 "NFT 마켓플레이스"를 통하여 유통되는 NFT 및 해당 NFT에 연계된 "디지털 저작물"에
                의하여 본인의 저작권 기타 권리가 침해된다고 주장하는 자(이하 "권리주장자")는 해당 사실을 소명하여 회사에
                해당 NFT의 이용 및 유통을 중단할 것을 요청할 수 있습니다.
              </li>
              <li>
                2. 회사는 제1항에 따라 NFT의 이용 및 유통 중단을 요구받은 경우에는 즉시 해당 NFT에 대한 "NFT 소유자의
                권리" 행사를 중단하고, 권리주장자, NFT를 보유하고 있는 회원 및 NFT 발행인에게 해당 사실을 통보하여야
                합니다.
              </li>
              <li>
                3. 제2항에 따라 통보를 받은 NFT 발행인이 자신에게 정당한 권리가 있음을 소명하면서 NFT의 이용 및 유통
                재개를 요구하는 경우, 회사는 재개요구사실 및 재개예정일을 권리주장자와 NFT를 보유하고 있는 회원에게
                지체없이 통보하고 해당 예정일에 "NFT 소유자의 권리"를 재개합니다. 다만, 권리주장자가 NFT 발행인의
                침해행위에 대하여 소를 제기한 사실을 재개예정일 전에 회사에 통보한 경우에는 그러하지 않습니다.
              </li>
              <li>
                {' '}
                4. 특정 NFT와 이에 연계된 디지털 저작물에 관한 권리 및/또는 "NFT IP" 관련하여 제3자가 권리 침해를
                주장하는 경우, 회사는 본 조에서 정한 바에 따라 해당 NFT의 거래 중개 등 서비스 제공을 중단하고, 지체없이
                권리주장자와 "NFT 발행인"에게 해당 사실을 알릴 것입니다. 회사는 해당 문제의 해결을 위하여 권리주장자와
                "NFT 발행인"간 협의를 주선하여 관련 분쟁이 조속히 해결되도록 노력할 것입니다. 회사는 관련 분쟁이 종결된
                후 중단되었던 NFT 거래 중개를 재개합니다. NFT 발행인과 권리주장자 사이의 분쟁이 해결되지 않아 해당 NFT를
                보유하고 있는 회원에게 손해가 발생하더라도 회사는 이에 대하여 책임지지 않습니다. 단, 회사의 고의 또는
                과실로 인하여 손해가 발생한 경우에는 법령에서 정한 범위 내에서 회사가 책임을 부담합니다.
              </li>
            </ul>
          </div>

          <div className="terms-box">
            <h3 className="terms-tit">제 8조 (명시되지 않은 사항)</h3>
            <p>
              본 이용약관에 명시되지 않은 사항은 관계 법령에서 정하는 바에 따르며, 관계 법령에서 정하지 아니한 사항은
              상관례를 따릅니다.
            </p>
          </div>

          <div className="terms-box">
            <h3 className="terms-tit">제 9조 (준거법 및 분쟁 해결 방법)</h3>
            <p>본 약관과 관련된 분쟁 발생 시 대한민국 법을 적용합니다.</p>
          </div>

          <div className="terms-box">
            <h3 className="terms-tit">부칙</h3>
            <p>이 약관은 2022년 10월 27일부터 적용됩니다.</p>
          </div>
        </div>
      </section>
    </article>
  );
};

export default TermPage;
