import { FC } from 'react';
import { TextModalProps } from '../FailMint';

const ConfirmModal: FC<TextModalProps> = ({ onClose, text }) => {
  return (
    <>
      <div className="popup-wrap" style={{ display: 'block' }}>
        <div className="dim" />

        <div className="pop-type01 upload-failed" style={{ display: 'block' }}>
          <strong className="pop-tit ico-fail">{text}</strong>
          {/*<p className="desc">{text}</p>*/}
          <a onClick={onClose} className="btn-type01 purple pop-close" style={{ cursor: 'pointer' }}>
            확인
          </a>
        </div>
      </div>
    </>
  );
};

export default ConfirmModal;
