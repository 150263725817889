import { ModalProps } from '../SuccessMint';
import { FC } from 'react';

export interface TextModalProps extends ModalProps {
  text: string;
}

const FailMintModal: FC<TextModalProps> = ({ onClose, text }) => {
  return (
    <>
      <div className="popup-wrap" style={{ display: 'block' }}>
        <div className="dim" />
        <div className="pop-type01 upload-failed">
          <strong className="pop-tit ico-fail">Upload Failed</strong>
          <p className="desc">{text}</p>
          <a onClick={onClose} style={{ cursor: 'pointer' }} className="btn-type01 purple pop-close">
            Close
          </a>
        </div>
      </div>
    </>
  );
};

export default FailMintModal;
