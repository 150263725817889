import React, { useEffect, useState } from 'react';
import './App.css';
import PublicRouter from './common/routes';
import Header from './components/Layout/Header';
import Footer from './components/Layout/Footer';
import { useRecoilState } from 'recoil';
import { accountInfoAtom } from './recoil/atoms';
import { APICore, setAuthorization } from './api';
import { getMyInfo } from './api/account';
import { useLocation } from 'react-router-dom';

function App() {
  const [accountInfo, setAccountInfo] = useRecoilState(accountInfoAtom);
  const [isLogin, setIsLogin] = useState(false);
  const location = useLocation();

  const bootstrap = async () => {
    try {
      const myInfo = await getMyInfo();

      setAccountInfo({
        address: myInfo.address,
        uid: myInfo.uid,
        sid: myInfo.sid,
        nonceVerified: myInfo.nonceVerified,
        name: myInfo.name,
        profile: myInfo.profile,
      });
      setIsLogin(true);
    } catch (err) {
      setAuthorization('');
      setIsLogin(false);
      setAccountInfo({
        address: '',
        uid: '',
        sid: '',
        nonceVerified: '',
        name: '',
        profile: '',
      });
    }
  };

  const getKaikasAccount = async () => {
    const api = new APICore();
    const address = await window.klaytn.selectedAddress;
    const isLogin = localStorage.getItem('isLogin');
    if (JSON.parse(isLogin!) && !address) {
      localStorage.removeItem('isLogin');
      api.setLoggedInUser(null);
      window.location.reload();
    }
  };

  useEffect(() => {
    bootstrap();
  }, []);

  useEffect(() => {
    getKaikasAccount();
  }, [location.pathname]);

  return (
    <main>
      <div id="contents" className="main">
        <Header />
        <PublicRouter />
        <Footer />
      </div>
      <div id="modal" />
    </main>
  );
}

export default App;
