import axios from 'axios';
import { AbiItem } from 'caver-js';
import { APICore } from '../api';
const api = new APICore();
export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export const onSignOut = () => {
  localStorage.removeItem('isLogin');
  api.setLoggedInUser(null);

  window.location.reload();
};
