import axios from 'axios';

import { APICore } from './index';
import { AbiItem } from 'caver-js';
//
const api = new APICore();
//
export interface MainBannerReq {
  url: string;
  popup: boolean;
  background: string;
}

export interface NftProjectReq {
  desc: string;
  idx: number;
  finish: number;
  homepage?: string;
  kakaotalk?: string;
  twitter?: string;
  telegram?: string;
  thumbnail: string;
  minted: number;
  start: number;
  maximum: number;
  title: string;
  blockchain: {
    contract: string;
  };
}

export interface AssetsNftItem {
  idx: number;
  price: number;
  maximum: number;
  minted: number;
  soldout: boolean;
  title: string;
  ticker: string;
  thumbnail: string;
  decimal: number;
  contract: string;
  abi: string;
}

export interface MyNftItem {
  name: string;
  symbol: string;
  tokenURI: string;
}

export interface IABI {
  nft: {
    abi: AbiItem[];
  };
}

export const getMainBanner = async (): Promise<MainBannerReq[]> => {
  return api.get('/apps/main/popup').then((res) => res.data.data.banners);
};

export const getNftProjectLists = (): Promise<NftProjectReq[]> => {
  return api.get('/nfts/project').then((resp) => resp.data.data.projects);
};

export const getNftAssetsNftLists = (idx: number): Promise<AssetsNftItem[]> => {
  return api.get(`/nfts/project/${idx}/items`).then((resp) => resp.data.data.items);
};

export const getRpcInfo = async () => {
  return api.get('/apps/blockchain').then((resp) => resp.data.data.blockchain);
};

export const saveTxId = async (nftidx: number, itemidx: number, txid: string, tag: string) => {
  return api.create(`/nfts/project/${nftidx}/items/${itemidx}/mint`, { txid, tag }).then((resp) => resp.data.data);
};

export const getNftProject = (idx: number): Promise<NftProjectReq> => {
  return api.get(`/nfts/project/${idx}`).then((resp) => resp.data.data.project);
};

export const getMintData = async (
  nftidx: number,
  itemidx: number
): Promise<{ tag: string; url: string; gasFee: number }> => {
  return api.get(`/nfts/project/${nftidx}/items/${itemidx}/mint/data`).then((resp) => resp.data.data);
};

export const getMtNft = (): Promise<MyNftItem[]> => {
  return api.get('/nfts/me').then((resp) => resp.data.data);
};

export const getAbiData = async (type: string, method: string, nftIdx: number, itemIdx: number): Promise<IABI> => {
  return api
    .get(`/nfts/project/${nftIdx}/items/${itemIdx}/abi?type=${type}&method=${method}`)
    .then((resp) => resp.data.data);
};
