import ico_sns_tel from '../../assets/images/ico_sns-tel.png';
import ico_sns_kakao from '../../assets/images/ico_sns-kakao.png';
import ico_sns_twitter from '../../assets/images/ico_sns-twitter.png';
import tel_disabled from '../../assets/images/icn-telegram-disabled@3x.png';
import kakao_disabled from '../../assets/images/icn-kakaotalk-disabled@3x.png';
import twitter_disabled from '../../assets/images/icn-twitter-disabled@3x.png';

import btn_arrow from '../../assets/images/btn_arrow.png';
import { useEffect, useState } from 'react';
import { AssetsNftItem, getNftAssetsNftLists, getNftProjectLists, getRpcInfo, NftProjectReq } from '../../api/app';
import styled from '@emotion/styled';
import NftAssets from '../../components/NftAssets';
import Caver from 'caver-js';
import { mintABI } from '../../caverConfig';
import { accountInfoAtom } from '../../recoil/atoms';
import { useRecoilState } from 'recoil';
import useModal from '../../hooks/useModal';
import SuccessMintModal from '../../components/Modal/SuccessMint';
import FailMintModal from '../../components/Modal/FailMint';

const ImgWrapper = styled.img`
  width: 620px;
  border-radius: 20px;

  @media (max-width: 1280px) {
    width: 327px;
    border-radius: 12px;
  }
`;

export const SpanWrapper = styled.span<{ width: string }>`
  width: ${(props) => props.width} !important;
`;

const MintPage = () => {
  const [nftLists, setNftLists] = useState<NftProjectReq[]>([]);
  const [projectLength, setProjectLength] = useState(-1);
  const [currentNftProject, setCurrentNftProject] = useState<NftProjectReq | null>(null);
  const [currentIdx, setCurrentIdx] = useState(-1);
  const [nftAssetLists, setNftAssetsLists] = useState<AssetsNftItem[]>([]);
  const [failMintMessage, setFailMintMessage] = useState('');
  const { ModalPortal: SuccessModal, openModal: successOpenModal, closeModal: successCloseModal } = useModal();
  const { ModalPortal: FailModal, openModal: failOpenModal, closeModal: failCloseModal } = useModal();
  const nftAssetsLists = async () => {
    const result = await getNftAssetsNftLists(currentIdx);
    setNftAssetsLists(result);
  };

  const handleNextProject = async () => {
    if (projectLength > 0) {
      /* nft 프로젝트 개수가 하나일때는 next 버튼 눌러도 그냥 그대로 */
      if (projectLength === 1) {
        return;
      }

      if (currentIdx === projectLength) {
        const filtered = nftLists.filter((item) => item.idx === 1);
        setCurrentNftProject(filtered[0]);
        setCurrentIdx(filtered[0].idx);
        await getNftAssetsNftLists(filtered[0].idx);
        return;
      }

      if (currentIdx < projectLength) {
        const increasedIdx = currentIdx + 1;
        const filtered = nftLists.filter((item) => item.idx === increasedIdx);
        setCurrentNftProject(filtered[0]);
        setCurrentIdx(increasedIdx);
        await getNftAssetsNftLists(increasedIdx);
        return;
      }
    }
  };

  const handlePreviousProject = async () => {
    if (projectLength > 0) {
      if (currentIdx === 1) {
        const filtered = nftLists.filter((item) => item.idx === projectLength);
        setCurrentNftProject(filtered[0]);
        setCurrentIdx(filtered[0].idx);
        await getNftAssetsNftLists(filtered[0].idx);
        return;
      }

      const decreasedIdx = currentIdx - 1;
      const filtered = nftLists.filter((item) => item.idx === decreasedIdx);
      setCurrentNftProject(filtered[0]);
      setCurrentIdx(decreasedIdx);
      await getNftAssetsNftLists(decreasedIdx);
    }
  };

  const bootstrap = async () => {
    const result = await getNftProjectLists();
    const rpcInfo = await getRpcInfo();
    setProjectLength(result.length);
    setNftLists(result);
    setCurrentNftProject(result.length > 0 ? result[0] : null);
    setCurrentIdx(result.length > 0 ? result[0].idx : -1);
  };

  useEffect(() => {
    bootstrap();
  }, []);

  useEffect(() => {
    if (currentIdx > 0) {
      nftAssetsLists();
    }
  }, [currentIdx]);

  return (
    <article>
      <section className="meta-mint">
        <div className="inner-box">
          <div className="top">
            <h2 className="tit">Mint</h2>
            <p className="desc">새롭고 유망한 NFT 프로젝트에 초기 투자하고 다양한 혜택을 누리세요.</p>
            {/*<a href="#" className="btn-gradi line">전체 프로젝트 보기</a>*/}
          </div>
          <div className="min-cont">
            <h3 className="sub-tit">Featured Project</h3>
            <div className="arrow-btn">
              <a className="btn-prev">
                <img src={btn_arrow} onClick={handlePreviousProject} alt="" style={{ cursor: 'pointer' }} />
              </a>
              <a className="btn-next" onClick={handleNextProject} style={{ cursor: 'pointer' }}>
                <img src={btn_arrow} alt="" />
              </a>
            </div>

            {currentNftProject && (
              <div className="feature-project flex">
                <figure className="cont-img">
                  <ImgWrapper src={currentNftProject.thumbnail} />
                </figure>
                <div className="info">
                  <strong className="small-tit">{currentNftProject.title}</strong>
                  <p>
                    {/*PENTAMIX는 국내 최대의 ART& TECH Project입니다.*/}
                    {/*<i className="br">NFT 생태계에 대한 이해를 기본으로 다양한 콜렉션을 발행하고 있습니다.</i>*/}
                    {currentNftProject.desc}
                  </p>
                  {currentNftProject?.homepage ? (
                    <a className="more" target="_blank" href={currentNftProject?.homepage}>
                      프로젝트 더보기
                    </a>
                  ) : (
                    <> </>
                  )}
                  <span className="sns">
                    {currentNftProject?.telegram ? (
                      <a href={currentNftProject?.telegram} target="_blank" rel="noreferrer noreferrer">
                        <img src={ico_sns_tel} alt="" />
                      </a>
                    ) : (
                      <a>
                        <img src={tel_disabled} />
                      </a>
                    )}
                    {currentNftProject?.kakaotalk ? (
                      <a href={currentNftProject?.kakaotalk} target="_blank" rel="noreferrer noreferrer">
                        <img src={ico_sns_kakao} alt="" />
                      </a>
                    ) : (
                      <a>
                        <img src={kakao_disabled} alt="" />
                      </a>
                    )}
                    {currentNftProject?.twitter ? (
                      <a href={currentNftProject?.twitter} target="_blank" rel="noreferrer noreferrer">
                        <img src={ico_sns_twitter} alt="" />
                      </a>
                    ) : (
                      <a>
                        <img src={twitter_disabled} alt="" />
                      </a>
                    )}
                  </span>

                  <div className="graph-wrap">
                    <div className="flex">
                      <div>
                        <span className="quantity-tit">판매 수량</span>
                        <em>{currentNftProject?.minted}</em>
                      </div>
                      <div className="text-r">
                        <span className="quantity-tit">목표 수량</span>
                        <em>{currentNftProject?.maximum}</em>
                      </div>
                    </div>
                    <div className="graph">
                      <SpanWrapper
                        className="bar"
                        width={`${String(
                          parseInt(String((currentNftProject.minted / currentNftProject.maximum) * 100))
                        )}%`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="assets-wrap">
              <h3 className="sub-tit">NFT Assets</h3>
              <ul className="assets-list">
                {nftAssetLists.map((item, i) => (
                  <NftAssets
                    key={item.idx}
                    {...item}
                    currentProject={currentNftProject}
                    successOpenModal={successOpenModal}
                    failOpenModal={failOpenModal}
                    setFailMintMessage={setFailMintMessage}
                    setCurrentNftProject={setCurrentNftProject}
                    setNftAssetsLists={setNftAssetsLists}
                  />
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <SuccessModal>
        <SuccessMintModal onClose={successCloseModal} />
      </SuccessModal>

      <FailModal>
        <FailMintModal text={failMintMessage} onClose={failCloseModal} />
      </FailModal>
    </article>
  );
};

export default MintPage;
