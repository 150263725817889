import { atom } from 'recoil';

export const pathnameInfoAtom = atom<{ text: string; link: string } | null>({
  key: 'pathnameInfo',
  default: null,
});
export const currentLangAtom = atom({
  key: 'currentLanguage',
  default: 'KOR',
});

export const accountInfoAtom = atom({
  key: 'accountInfo',
  default: {
    address: '',
    name: '',
    nonceVerified: '',
    profile: '',
    uid: '',
    sid: '',
  },
});

export const isLoginAtom = atom({
  key: 'isLoginAtom',
  default: false,
});
