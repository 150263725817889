import React, { FC } from 'react';
import { MyNftResult } from '../Profile/ConnectedWallet';

interface Props extends MyNftResult {
  openModal: () => void;
}
export const cursorHoverStyle = { cursor: 'pointer' };

const MyNftLists: FC<Props> = ({ openModal, title, name, image, description }) => {
  return (
    <>
      <li>
        <a>
          <figure className="cont-img">
            <img src={image} />
          </figure>
        </a>
        <div className="txt">
          <strong className="item-tit">{title}</strong>
          <span className="collec-name">{name}</span>
          <div className="btn">
            <a className="btn-gradi" onClick={openModal} style={cursorHoverStyle}>
              Transfer
            </a>
            <a className="btn-gradi line" onClick={openModal} style={cursorHoverStyle}>
              Sell
            </a>
          </div>
        </div>
      </li>
    </>
  );
};

export default MyNftLists;
