import { onSignOut } from '../../utils';
import { useRecoilValue } from 'recoil';
import { accountInfoAtom } from '../../recoil/atoms';
import { useEffect, useState } from 'react';
import ConnectedWallet from '../../components/Profile/ConnectedWallet';
import IsNoConnectedWallet from '../../components/Profile/isNoConnectedWallet';
import { useLocation } from 'react-router-dom';
import useInput from '../../hooks/useInput';

const ProfilePage = () => {
  const accountInfo = useRecoilValue(accountInfoAtom);
  const [isCheckLogin, setIsCheckLogin] = useState(false);
  const location = useLocation();

  const bootstrap = async () => {
    const isLogin = JSON.parse(localStorage.getItem('isLogin')!);
    const address = await window.klaytn.selectedAddress;
    console.log(address);
    if ((!isLogin || !address) && accountInfo?.address) {
      onSignOut();
    }
    // if ((!isLogin || !address) && accountInfo?.address) {
    //   onSignOut();
    //   return;
    // }

    if (!isLogin) {
      setIsCheckLogin(false);
      return;
    }

    setIsCheckLogin(true);
  };

  useEffect(() => {
    bootstrap();
  }, [accountInfo?.address, location.pathname]);

  return <>{isCheckLogin ? <ConnectedWallet /> : <IsNoConnectedWallet />}</>;
};

export default ProfilePage;
