import HomeSectionOne from '../../components/Home/SectionOne';

const HomePage = () => {
  return (
    <>
      <article>
        <HomeSectionOne />
        <section className="marketplace">
          <div className="inner-box">
            <div className="top flex">
              <h2 className="tit">NFT Marketplace</h2>
              <span className="input">
                <input type="text" placeholder="Search item" />
              </span>
            </div>
            <div className="item-empty">
              <strong>No Items Yet</strong>
            </div>
          </div>
        </section>
        {/*<section className="marketplace">*/}
        {/*  <div className="inner-box">*/}
        {/*    <div className="top flex">*/}
        {/*      <h2 className="tit">NFT Marketplace</h2>*/}
        {/*      <span className="input">*/}
        {/*        <input type="text" placeholder="Search item" />*/}
        {/*      </span>*/}
        {/*    </div>*/}
        {/*    <ul className="item-list">*/}
        {/*      <li>*/}
        {/*        <a href="#!">*/}
        {/*          <figure className="cont-img"></figure>*/}
        {/*          <div className="txt">*/}
        {/*            <strong className="item-tit">Artwork Title</strong>*/}
        {/*            <span className="collec-name">Collection Name</span>*/}
        {/*            <div className="info">*/}
        {/*              <div className="creator">*/}
        {/*                <figure>*/}
        {/*                  <img src="./images/img_pfp-default.png" alt="" />*/}
        {/*                </figure>*/}
        {/*                <span>*/}
        {/*                  <span className="item-tit-s">Creator</span>*/}
        {/*                  <em>Creator Name</em>*/}
        {/*                </span>*/}
        {/*              </div>*/}
        {/*              <div className="curr-price">*/}
        {/*                <span className="item-tit-s">Current Price</span>*/}
        {/*                <em className="klay">0.0 KLAY</em>*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*            <p className="date">6 hours ago (date of upload)</p>*/}
        {/*          </div>*/}
        {/*        </a>*/}
        {/*      </li>*/}
        {/*      <li>*/}
        {/*        <a href="#!">*/}
        {/*          <figure className="cont-img"></figure>*/}
        {/*          <div className="txt">*/}
        {/*            <strong className="item-tit">Artwork Title</strong>*/}
        {/*            <span className="collec-name">Collection Name</span>*/}
        {/*            <div className="info">*/}
        {/*              <div className="creator">*/}
        {/*                <figure>*/}
        {/*                  <img src="./images/img_pfp-default.png" alt="" />*/}
        {/*                </figure>*/}
        {/*                <span>*/}
        {/*                  <span className="item-tit-s">Creator</span>*/}
        {/*                  <em>Creator Name</em>*/}
        {/*                </span>*/}
        {/*              </div>*/}
        {/*              <div className="curr-price">*/}
        {/*                <span className="item-tit-s">Current Price</span>*/}
        {/*                <em className="klay">0.0 KLAY</em>*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*            <p className="date">6 hours ago (date of upload)</p>*/}
        {/*          </div>*/}
        {/*        </a>*/}
        {/*      </li>*/}
        {/*      <li>*/}
        {/*        <a href="#!">*/}
        {/*          <figure className="cont-img"></figure>*/}
        {/*          <div className="txt">*/}
        {/*            <strong className="item-tit">Artwork Title</strong>*/}
        {/*            <span className="collec-name">Collection Name</span>*/}
        {/*            <div className="info">*/}
        {/*              <div className="creator">*/}
        {/*                <figure>*/}
        {/*                  <img src="./images/img_pfp-default.png" alt="" />*/}
        {/*                </figure>*/}
        {/*                <span>*/}
        {/*                  <span className="item-tit-s">Creator</span>*/}
        {/*                  <em>Creator Name</em>*/}
        {/*                </span>*/}
        {/*              </div>*/}
        {/*              <div className="curr-price">*/}
        {/*                <span className="item-tit-s">Current Price</span>*/}
        {/*                <em className="klay">0.0 KLAY</em>*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*            <p className="date">6 hours ago (date of upload)</p>*/}
        {/*          </div>*/}
        {/*        </a>*/}
        {/*      </li>*/}
        {/*      <li>*/}
        {/*        <a href="#!">*/}
        {/*          <figure className="cont-img"></figure>*/}
        {/*          <div className="txt">*/}
        {/*            <strong className="item-tit">Artwork Title</strong>*/}
        {/*            <span className="collec-name">Collection Name</span>*/}
        {/*            <div className="info">*/}
        {/*              <div className="creator">*/}
        {/*                <figure>*/}
        {/*                  <img src="./images/img_pfp-default.png" alt="" />*/}
        {/*                </figure>*/}
        {/*                <span>*/}
        {/*                  <span className="item-tit-s">Creator</span>*/}
        {/*                  <em>Creator Name</em>*/}
        {/*                </span>*/}
        {/*              </div>*/}
        {/*              <div className="curr-price">*/}
        {/*                <span className="item-tit-s">Current Price</span>*/}
        {/*                <em className="klay">0.0 KLAY</em>*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*            <p className="date">6 hours ago (date of upload)</p>*/}
        {/*          </div>*/}
        {/*        </a>*/}
        {/*      </li>*/}
        {/*      <li>*/}
        {/*        <a href="#!">*/}
        {/*          <figure className="cont-img"></figure>*/}
        {/*          <div className="txt">*/}
        {/*            <strong className="item-tit">Artwork Title</strong>*/}
        {/*            <span className="collec-name">Collection Name</span>*/}
        {/*            <div className="info">*/}
        {/*              <div className="creator">*/}
        {/*                <figure>*/}
        {/*                  <img src="./images/img_pfp-default.png" alt="" />*/}
        {/*                </figure>*/}
        {/*                <span>*/}
        {/*                  <span className="item-tit-s">Creator</span>*/}
        {/*                  <em>Creator Name</em>*/}
        {/*                </span>*/}
        {/*              </div>*/}
        {/*              <div className="curr-price">*/}
        {/*                <span className="item-tit-s">Current Price</span>*/}
        {/*                <em className="klay">0.0 KLAY</em>*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*            <p className="date">6 hours ago (date of upload)</p>*/}
        {/*          </div>*/}
        {/*        </a>*/}
        {/*      </li>*/}
        {/*      <li>*/}
        {/*        <a href="#!">*/}
        {/*          <figure className="cont-img"></figure>*/}
        {/*          <div className="txt">*/}
        {/*            <strong className="item-tit">Artwork Title</strong>*/}
        {/*            <span className="collec-name">Collection Name</span>*/}
        {/*            <div className="info">*/}
        {/*              <div className="creator">*/}
        {/*                <figure>*/}
        {/*                  <img src="./images/img_pfp-default.png" alt="" />*/}
        {/*                </figure>*/}
        {/*                <span>*/}
        {/*                  <span className="item-tit-s">Creator</span>*/}
        {/*                  <em>Creator Name</em>*/}
        {/*                </span>*/}
        {/*              </div>*/}
        {/*              <div className="curr-price">*/}
        {/*                <span className="item-tit-s">Current Price</span>*/}
        {/*                <em className="klay">0.0 KLAY</em>*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*            <p className="date">6 hours ago (date of upload)</p>*/}
        {/*          </div>*/}
        {/*        </a>*/}
        {/*      </li>*/}
        {/*      <li>*/}
        {/*        <a href="#!">*/}
        {/*          <figure className="cont-img"></figure>*/}
        {/*          <div className="txt">*/}
        {/*            <strong className="item-tit">Artwork Title</strong>*/}
        {/*            <span className="collec-name">Collection Name</span>*/}
        {/*            <div className="info">*/}
        {/*              <div className="creator">*/}
        {/*                <figure>*/}
        {/*                  <img src="./images/img_pfp-default.png" alt="" />*/}
        {/*                </figure>*/}
        {/*                <span>*/}
        {/*                  <span className="item-tit-s">Creator</span>*/}
        {/*                  <em>Creator Name</em>*/}
        {/*                </span>*/}
        {/*              </div>*/}
        {/*              <div className="curr-price">*/}
        {/*                <span className="item-tit-s">Current Price</span>*/}
        {/*                <em className="klay">0.0 KLAY</em>*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*            <p className="date">6 hours ago (date of upload)</p>*/}
        {/*          </div>*/}
        {/*        </a>*/}
        {/*      </li>*/}
        {/*      <li>*/}
        {/*        <a href="#!">*/}
        {/*          <figure className="cont-img"></figure>*/}
        {/*          <div className="txt">*/}
        {/*            <strong className="item-tit">Artwork Title</strong>*/}
        {/*            <span className="collec-name">Collection Name</span>*/}
        {/*            <div className="info">*/}
        {/*              <div className="creator">*/}
        {/*                <figure>*/}
        {/*                  <img src="./images/img_pfp-default.png" alt="" />*/}
        {/*                </figure>*/}
        {/*                <span>*/}
        {/*                  <span className="item-tit-s">Creator</span>*/}
        {/*                  <em>Creator Name</em>*/}
        {/*                </span>*/}
        {/*              </div>*/}
        {/*              <div className="curr-price">*/}
        {/*                <span className="item-tit-s">Current Price</span>*/}
        {/*                <em className="klay">0.0 KLAY</em>*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*            <p className="date">6 hours ago (date of upload)</p>*/}
        {/*          </div>*/}
        {/*        </a>*/}
        {/*      </li>*/}
        {/*      <li>*/}
        {/*        <a href="#!">*/}
        {/*          <figure className="cont-img"></figure>*/}
        {/*          <div className="txt">*/}
        {/*            <strong className="item-tit">Artwork Title</strong>*/}
        {/*            <span className="collec-name">Collection Name</span>*/}
        {/*            <div className="info">*/}
        {/*              <div className="creator">*/}
        {/*                <figure>*/}
        {/*                  <img src="./images/img_pfp-default.png" alt="" />*/}
        {/*                </figure>*/}
        {/*                <span>*/}
        {/*                  <span className="item-tit-s">Creator</span>*/}
        {/*                  <em>Creator Name</em>*/}
        {/*                </span>*/}
        {/*              </div>*/}
        {/*              <div className="curr-price">*/}
        {/*                <span className="item-tit-s">Current Price</span>*/}
        {/*                <em className="klay">0.0 KLAY</em>*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*            <p className="date">6 hours ago (date of upload)</p>*/}
        {/*          </div>*/}
        {/*        </a>*/}
        {/*      </li>*/}
        {/*      <li>*/}
        {/*        <a href="#!">*/}
        {/*          <figure className="cont-img"></figure>*/}
        {/*          <div className="txt">*/}
        {/*            <strong className="item-tit">Artwork Title</strong>*/}
        {/*            <span className="collec-name">Collection Name</span>*/}
        {/*            <div className="info">*/}
        {/*              <div className="creator">*/}
        {/*                <figure>*/}
        {/*                  <img src="./images/img_pfp-default.png" alt="" />*/}
        {/*                </figure>*/}
        {/*                <span>*/}
        {/*                  <span className="item-tit-s">Creator</span>*/}
        {/*                  <em>Creator Name</em>*/}
        {/*                </span>*/}
        {/*              </div>*/}
        {/*              <div className="curr-price">*/}
        {/*                <span className="item-tit-s">Current Price</span>*/}
        {/*                <em className="klay">0.0 KLAY</em>*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*            <p className="date">6 hours ago (date of upload)</p>*/}
        {/*          </div>*/}
        {/*        </a>*/}
        {/*      </li>*/}
        {/*      <li>*/}
        {/*        <a href="#!">*/}
        {/*          <figure className="cont-img"></figure>*/}
        {/*          <div className="txt">*/}
        {/*            <strong className="item-tit">Artwork Title</strong>*/}
        {/*            <span className="collec-name">Collection Name</span>*/}
        {/*            <div className="info">*/}
        {/*              <div className="creator">*/}
        {/*                <figure>*/}
        {/*                  <img src="./images/img_pfp-default.png" alt="" />*/}
        {/*                </figure>*/}
        {/*                <span>*/}
        {/*                  <span className="item-tit-s">Creator</span>*/}
        {/*                  <em>Creator Name</em>*/}
        {/*                </span>*/}
        {/*              </div>*/}
        {/*              <div className="curr-price">*/}
        {/*                <span className="item-tit-s">Current Price</span>*/}
        {/*                <em className="klay">0.0 KLAY</em>*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*            <p className="date">6 hours ago (date of upload)</p>*/}
        {/*          </div>*/}
        {/*        </a>*/}
        {/*      </li>*/}
        {/*      <li>*/}
        {/*        <a href="#!">*/}
        {/*          <figure className="cont-img"></figure>*/}
        {/*          <div className="txt">*/}
        {/*            <strong className="item-tit">Artwork Title</strong>*/}
        {/*            <span className="collec-name">Collection Name</span>*/}
        {/*            <div className="info">*/}
        {/*              <div className="creator">*/}
        {/*                <figure>*/}
        {/*                  <img src="./images/img_pfp-default.png" alt="" />*/}
        {/*                </figure>*/}
        {/*                <span>*/}
        {/*                  <span className="item-tit-s">Creator</span>*/}
        {/*                  <em>Creator Name</em>*/}
        {/*                </span>*/}
        {/*              </div>*/}
        {/*              <div className="curr-price">*/}
        {/*                <span className="item-tit-s">Current Price</span>*/}
        {/*                <em className="klay">0.0 KLAY</em>*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*            <p className="date">6 hours ago (date of upload)</p>*/}
        {/*          </div>*/}
        {/*        </a>*/}
        {/*      </li>*/}
        {/*    </ul>*/}
        {/*  </div>*/}
        {/*</section>*/}
      </article>
    </>
  );
};

export default HomePage;
