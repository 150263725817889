import { FC } from 'react';

export interface ModalProps {
  onClose: () => void;
}

const SuccessMintModal: FC<ModalProps> = ({ onClose }) => {
  return (
    <>
      <div className="popup-wrap" style={{ display: 'block' }}>
        <div className="dim" />

        <div className="pop-type01 upload-success" style={{ display: 'block' }}>
          <strong className="pop-tit ico-success">Success Mint</strong>
          <p className="desc">
            민팅 요청 성공했습니다.<i className="br">실제 NFT 지급까지 시간이 소요될 수 있습니다.</i>
          </p>
          {/*<div className="share-sns">*/}
          {/*  <em>Share your product</em>*/}
          {/*  <span>*/}
          {/*    <a href="#">*/}
          {/*      <img src="./images/ico_sns-tel.png" alt="" />*/}
          {/*    </a>*/}
          {/*    <a href="#">*/}
          {/*      <img src="./images/ico_sns-kakao.png" alt="" />*/}
          {/*    </a>*/}
          {/*    <a href="#">*/}
          {/*      <img src="./images/ico_sns-twitter.png" alt="" />*/}
          {/*    </a>*/}
          {/*  </span>*/}
          {/*</div>*/}
          <a onClick={onClose} style={{ cursor: 'pointer' }} className="btn-type01 purple pop-close">
            완료
          </a>
        </div>
      </div>
    </>
  );
};

export default SuccessMintModal;
