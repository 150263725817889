import Carousel from 'nuka-carousel';

import bg_kv_ellipse from '../../assets/images/bg_kv-ellipse.png';
import bg_kv_ellipse02 from '../../assets/images/bg_kv-ellipse02.png';
import img_kv_hero from '../../assets/images/img_kv-hero.png';
import img_kv_hero_m from '../../assets/images/img_kv-hero-m.png';
import ico_sns_tel from '../../assets/images/ico_sns-tel.png';
import ico_sns_kakao from '../../assets/images/ico_sns-kakao.png';
import ico_sns_twitter from '../../assets/images/ico_sns-twitter.png';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { getMainBanner, MainBannerReq } from '../../api/app';

const FigureWrapper = styled.figure`
  display: flex;
  justify-content: center;
`;

const HomeSectionOne = () => {
  const [banners, setBanners] = useState<MainBannerReq[]>([]);
  const bootstrap = async () => {
    getMainBanner().then(setBanners);
  };

  useEffect(() => {
    bootstrap();
  }, []);
  return (
    <section className="kv">
      <div className="slide-wrap">
        <Carousel withoutControls autoplay wrapAround={true} autoplayInterval={5000}>
          {banners.map((banner, i) => (
            <li>
              <FigureWrapper>
                <span className="pc">
                  <img src={banner.background} alt="" />
                </span>
                <span className="mobile">
                  <img src={banner.background} alt="" />
                </span>
              </FigureWrapper>
            </li>
          ))}
          {/*<li>*/}
          {/*  <FigureWrapper>*/}
          {/*    <span className="pc">*/}
          {/*      <img src={img_kv_hero} alt="" />*/}
          {/*    </span>*/}
          {/*    <span className="mobile">*/}
          {/*      <img src={img_kv_hero_m} alt="" />*/}
          {/*    </span>*/}
          {/*  </FigureWrapper>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <FigureWrapper>*/}
          {/*    <span className="pc">*/}
          {/*      <img src={img_kv_hero} alt="" />*/}
          {/*    </span>*/}
          {/*    <span className="mobile">*/}
          {/*      <img src={img_kv_hero_m} alt="" />*/}
          {/*    </span>*/}
          {/*  </FigureWrapper>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <figure>*/}
          {/*    <span className="pc">*/}
          {/*      <img src="./images/img_kv-hero.png" alt="" />*/}
          {/*    </span>*/}
          {/*    <span className="mobile">*/}
          {/*      <img src="./images/img_kv-hero-m.png" alt="" />*/}
          {/*    </span>*/}
          {/*  </figure>*/}
          {/*</li>*/}
        </Carousel>
      </div>

      {/*  <div className="ellipse">*/}
      {/*  <img src={bg_kv_ellipse} alt="" />*/}
      {/*  <span className="top">*/}
      {/*      <img src={bg_kv_ellipse02} alt="" />*/}
      {/*    </span>*/}
      {/*</div>*/}
      {/*<Carousel withoutControls autoplay wrapAround={true} autoplayInterval={5000}>*/}
      {/*  {banners?.map((banner, i) => (*/}
      {/*    <FigureWrapper key={i}>*/}
      {/*      <span className="pc">*/}
      {/*        <img src={banner.background} alt="banner" />*/}
      {/*      </span>*/}
      {/*      <span className="mobile">*/}
      {/*        <img src={banner.background} alt="" />*/}
      {/*      </span>*/}
      {/*    </FigureWrapper>*/}
      {/*  ))}*/}
      {/*</Carousel>*/}

      <p>
        내 작품이 예술이 되는 공간, <i className="br">NFT의 모든 것을 META COLABO와 함께 하세요.</i>
      </p>
      <span className="sns">
        <a href="https://t.me/META_colabo" target="_blank" rel="noopener noreferrer">
          <img src={ico_sns_tel} alt="" />
        </a>
        <a href="https://open.kakao.com/o/gXqZfbte" target="_blank" rel="noopener noreferrer">
          <img src={ico_sns_kakao} alt="" />
        </a>
        <a href="https://www.twitter.com/meta_colabo" target="_blank" rel="noopener noreferrer">
          <img src={ico_sns_twitter} alt="" />
        </a>
      </span>
    </section>
  );
};

export default HomeSectionOne;
