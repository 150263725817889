import { routes } from '../../../common/constants';
import { Link } from 'react-router-dom';

const Footer = () => {
  const { PRIVATE, TERM } = routes;

  return (
    <footer>
      <p className="copy">© metacolabo. All right reserved.</p>
      <span>
        <Link to={TERM}>Terms</Link>
        <Link to={PRIVATE}>Privacy Policy</Link>
      </span>
    </footer>
  );
};

export default Footer;
